exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cases-index-js": () => import("./../../../src/pages/cases/index.js" /* webpackChunkName: "component---src-pages-cases-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dev-404-page-js": () => import("./../../../src/pages/dev-404-page.js" /* webpackChunkName: "component---src-pages-dev-404-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-index-js": () => import("./../../../src/pages/new/index.js" /* webpackChunkName: "component---src-pages-new-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-ios-js": () => import("./../../../src/pages/services/ios.js" /* webpackChunkName: "component---src-pages-services-ios-js" */),
  "component---src-pages-services-website-development-js": () => import("./../../../src/pages/services/website-development.js" /* webpackChunkName: "component---src-pages-services-website-development-js" */)
}

