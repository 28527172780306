import React from "react"
import { Link } from "gatsby"

const LinkUnderline = props => {
  var type = ""
  if (props.underlined) {
    type = "d"
  }
  return (
    <Link
      to={props.to}
      className={props.className + " underline" + type + " " + props.color}
    >
      {props.children}
    </Link>
  )
}

LinkUnderline.defaultProps = {
  to: `/`,
  color: ``,
  children: ``,
  className: "",
}

export default LinkUnderline
