import React from "react";
import { Link } from "gatsby";

const Logo = ({ width = "40px", height = "40px" }) => {
  return (
    <Link className="logo" style={{ width, height }} to="/">
      <svg
        style={{
          width: width,
          height: height,
        }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 241 256"
      >
        <path d="M239.1,111.8l-14,8.1a3.7,3.7,0,0,1-3.8,0l-30.4-17.6a4.2,4.2,0,0,0-3.8,0l-19.5,11.3a3.8,3.8,0,0,0-1.9,3.3v5.7a3.9,3.9,0,0,0,5.8,3.3L189,115.8h0l15.9,9.1a3.9,3.9,0,0,1,0,6.7l-37.3,21.5a4,4,0,0,0-1.9,3.3v9.8a3.7,3.7,0,0,1-1.9,3.3l-30.5,17.6a3.9,3.9,0,0,0-1.9,3.3v9.7a3.9,3.9,0,0,0,1.9,3.3L163.8,221a3.7,3.7,0,0,1,1.9,3.3v15.3a3.8,3.8,0,0,1-5.7,3.3l-24.6-14.2a2.6,2.6,0,0,0-4,2.3v21.2a3.8,3.8,0,0,1-3.8,3.8h-14a3.8,3.8,0,0,1-3.8-3.8V232.3A3.9,3.9,0,0,0,104,229L81,242.3a3.8,3.8,0,0,1-5.7-3.3V223.9a3.9,3.9,0,0,1,1.9-3.3l9.2-5.3h0l21.4-12.4a3.7,3.7,0,0,0,2-3.3v-8.5a3.8,3.8,0,0,0-2-3.3L77.2,170.1a3.7,3.7,0,0,1-1.9-3.3V156.5a4,4,0,0,0-1.9-3.3L36.2,131.7a3.8,3.8,0,0,1,0-6.6l14-8.1a3.7,3.7,0,0,1,3.8,0l15.5,9a3.9,3.9,0,0,0,5.8-3.3v-6a4.1,4.1,0,0,0-1.9-3.3l-19.2-11a3.7,3.7,0,0,0-3.8,0L19.8,120a3.7,3.7,0,0,1-3.8,0L2,111.9a3.8,3.8,0,0,1,0-6.6L24.9,92.1a3.8,3.8,0,0,0,0-6.6l-17-9.9a3.9,3.9,0,0,1-1.4-5.2l7-12.1a3.9,3.9,0,0,1,5.3-1.4l16.3,9.4A3.8,3.8,0,0,0,40.8,63V36.8a3.8,3.8,0,0,1,5.7-3.3l14,8.1a3.6,3.6,0,0,1,1.9,3.3v35a4,4,0,0,0,1.9,3.3L95,100.9a3.9,3.9,0,0,1,1.9,3.3v51a3.9,3.9,0,0,0,1.9,3.3l19.5,11.2a3.7,3.7,0,0,0,3.8,0l20.1-11.6a3.7,3.7,0,0,0,1.9-3.3V104.4a3.7,3.7,0,0,1,1.9-3.3l29.9-17.3a3.7,3.7,0,0,0,1.9-3.3v-35a3.9,3.9,0,0,1,1.9-3.3l14-8a3.8,3.8,0,0,1,5.7,3.3V63.6a3.9,3.9,0,0,0,5.8,3.3l18.3-10.6a3.9,3.9,0,0,1,5.3,1.4l7,12.1a3.9,3.9,0,0,1-1.4,5.2L216.3,85.4a3.9,3.9,0,0,0,0,6.7l22.8,13.1A3.8,3.8,0,0,1,239.1,111.8Z" />
        <path d="M165.8,57V73.1a4.1,4.1,0,0,1-1.9,3.3l-41.3,23.7a3.7,3.7,0,0,1-3.8,0l-41.6-24a3.6,3.6,0,0,1-1.9-3.3V56.7A3.8,3.8,0,0,1,81,53.4l37.8,21.8a3.7,3.7,0,0,0,3.8,0c11.9-6.8,25.3-14.5,37.4-21.6A3.9,3.9,0,0,1,165.8,57Z" />
        <path d="M62.4,164.6V172a3.6,3.6,0,0,1-1.9,3.3L18.3,199.7a3.7,3.7,0,0,1-5.2-1.4l-7-12.1A3.8,3.8,0,0,1,7.5,181l17-9.8a3.8,3.8,0,0,0,0-6.6L1.9,151.5a3.8,3.8,0,0,1,0-6.6l13.2-7.6a3.7,3.7,0,0,1,3.8,0c12.5,7.1,29.3,16.8,41.6,24A3.6,3.6,0,0,1,62.4,164.6Z" />
        <path d="M89.1,198.3,46.5,222.9a3.8,3.8,0,0,1-5.7-3.3V203.4a3.9,3.9,0,0,1,1.9-3.3l28.5-16.5a3.9,3.9,0,0,1,3.9,0l14,8.1A3.8,3.8,0,0,1,89.1,198.3Z" />
        <path d="M165.8,17.3V33.4a4,4,0,0,1-1.9,3.3L122.6,60.5a3.7,3.7,0,0,1-3.8,0l-41.6-24a3.6,3.6,0,0,1-1.9-3.3V17.1A3.8,3.8,0,0,1,81,13.8l23.1,13.3a3.8,3.8,0,0,0,5.7-3.3V3.8A3.8,3.8,0,0,1,113.6,0h14a3.8,3.8,0,0,1,3.8,3.8V23.9a3.8,3.8,0,0,0,5.7,3.3L160,14A3.8,3.8,0,0,1,165.8,17.3Z" />
        <path d="M199.5,203v16.2a3.8,3.8,0,0,1-5.7,3.3l-41.3-23.8a3.8,3.8,0,0,1,0-6.6l14-8.1a3.7,3.7,0,0,1,3.8,0l27.3,15.7A3.9,3.9,0,0,1,199.5,203Z" />
        <path d="M224.5,175.6l8.5,4.9a3.8,3.8,0,0,1,1.4,5.2l-7,12.1a3.7,3.7,0,0,1-5.2,1.4l-42.4-24.4a3.9,3.9,0,0,1-1.9-3.3v-6.8a3.7,3.7,0,0,1,1.9-3.3L222.1,137a3.7,3.7,0,0,1,3.8,0l13,7.5a3.8,3.8,0,0,1,0,6.6l-22.7,13.1a3.8,3.8,0,0,0,0,6.6Z" />
      </svg>
    </Link>
  );
};

export default Logo;
