import React, { useEffect, useState } from "react";
import Header from "./Header";
import "../styles/global.css";
import Pop from "./Pop";
import LinkUnderline from "./Link";
import Logo from "./Logo";
import {
  useMotionValueEvent,
  useScroll,
  AnimatePresence,
  motion,
} from "framer-motion";

export const PageWrapper = ({ children, location }) => {
  const { scrollY } = useScroll();

  const scrollHandler = () => {
    let waypoints = document.querySelectorAll(".a-waypoint");
    let last = waypoints[0];
    const hw = window.innerHeight;

    for (let i = 0; i < waypoints.length; i++) {
      if (waypoints[i].getBoundingClientRect().top < hw * 0.6) {
        last = waypoints[i];
      }
    }
    document.body.classList.value = last.dataset["apperance"];
  };

  useMotionValueEvent(scrollY, "change", (_scroll) => {
    scrollHandler();
  });

  useEffect(scrollHandler, [location]);

  return (
    <>
      <Header />
      <main>{children}</main>
      <Pop>
        <footer
          data-apperance={"light"}
          className="a-waypoint"
          style={{
            borderTop: "1px solid rgba(143,145,154,.5)",
          }}
        >
          <div className="row footer_logo">
            <Logo />
          </div>
          <div style={{ marginTop: "3rem" }} className="row footer_flex">
            <div>
              <address>
                <div className="address_name">
                  <LinkUnderline to="/feedback/">
                    Свяжитесь с нами
                  </LinkUnderline>
                </div>
                <div className="address_email">
                  <a className="underline grey" href="mailto:hi@snowdigital.by">
                    hi@snowdigital.by
                  </a>
                </div>
                <div className="address_phone">
                  <a className="underline grey" href="tel:+375292728151">
                    +375 (29) 272-81-51
                  </a>
                </div>
                <div className="address_law color-secondary">
                  УНП: 791186570
                </div>
              </address>
            </div>
            <div>
              <ul>
                <li>
                  <LinkUnderline color="grey" to="/new/">
                    Разработка нового проекта
                  </LinkUnderline>
                </li>
                <li>
                  <LinkUnderline color="grey" to="/new/site/">
                    Разработка нового сайта
                  </LinkUnderline>
                </li>
                <li>
                  <LinkUnderline color="grey" to="/new/app/">
                    Разработка нового приложения
                  </LinkUnderline>
                </li>
                <li>
                  <LinkUnderline color="grey" to="/modify/">
                    Улучшение существующего проекта
                  </LinkUnderline>
                </li>
                <li>
                  <LinkUnderline color="grey" to="/promote/">
                    Продвижение в интернете
                  </LinkUnderline>
                </li>
                <li>
                  <LinkUnderline color="grey" to="/cases/">
                    Варианты проектов
                  </LinkUnderline>
                </li>
                <li>
                  <LinkUnderline color="grey" to="/services/">
                    Полный список услуг
                  </LinkUnderline>
                </li>
              </ul>
              <ul>
                <li>
                  <LinkUnderline color="grey" to="/portfolio/">
                    Наши работы
                  </LinkUnderline>
                </li>
                <li>
                  <LinkUnderline color="grey" to="/thesis/">
                    Почему мы
                  </LinkUnderline>
                </li>
                <li>
                  <LinkUnderline color="grey" to="/feedback/">
                    Консультация
                  </LinkUnderline>
                </li>
                <li>
                  <LinkUnderline color="grey" to="/job/">
                    Вакасии
                  </LinkUnderline>
                </li>
              </ul>
            </div>
          </div>
          <div
            style={{ padding: "100px 0 80px" }}
            className="row footer_flex footer_flex--contacts"
          >
            <div>
              <small>© Snowdigital {new Date().getFullYear()}</small>
            </div>
            <div>
              <a className="underline" href="https://vk.com/snowdigital">
                Вконтакте
              </a>
              <a
                className="underline"
                href="https://www.facebook.com/SnowDigital-100978064681933/"
              >
                Facebook
              </a>
              <a className="underline" href="https://twitter.com/snowdigitalby">
                Twitter
              </a>
              <a
                className="underline"
                href="https://www.instagram.com/snowdigitalinc/"
              >
                Instagram
              </a>
            </div>
          </div>
        </footer>
      </Pop>
    </>
  );
};

export const HeadWrapper = ({
  children,
  title = "Snowdigital | Студия Веб-разработки. Делаем ваши задумки реальностью.",
  description = "Разрабатываем сайты и приложения любой сложности. Snowdigital - создание высококачественных цифровых решений для вашего бизнеса. Создайте свое будущее вместе с нами.",
}) => {
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://snowdigital.by/og.png" />
      {children}
    </>
  );
};
