import React, { useEffect } from "react";
import Logo from "../components/Logo";
import LinkUnderline from "../components/Link";
import Borgar from "../svg/borgar.svg";
import { useMotionValueEvent, useScroll } from "framer-motion";

const Header = () => {
  const [expanded, setExpanded] = React.useState(false);
  const { scrollY } = useScroll();
  const prevscroll = React.useRef(0);
  const [headerClass, setHeaderClass] = React.useState("start");

  const expand = () => {
    setExpanded(!expanded);
    document.body.classList.remove("noscroll");
    if (!expanded) {
      document.body.classList.add("noscroll");
    }
  };

  useMotionValueEvent(scrollY, "change", (_scroll) => {
    const windowHeight = window.innerHeight;
    if (_scroll < 50) {
      document.body.dataset["header"] = "start";
      setHeaderClass("");
    } else {
      if (_scroll > prevscroll.current) {
        document.body.dataset["header"] = "hidden";
        setHeaderClass("hidden");
      } else {
        if (_scroll < document.body.offsetHeight - windowHeight * 2) {
          document.body.dataset["header"] = "show";
          setHeaderClass("show");
        }
      }
    }
    prevscroll.current = _scroll;
  });

  return (
    <header className={headerClass} data-collapsed={expanded}>
      <Logo />
      <center>
        <LinkUnderline className="header-links--desktopLink" to="/cases/">
          Кейсы
        </LinkUnderline>
        <LinkUnderline className="header-links--desktopLink" to="/services/">
          Услуги
        </LinkUnderline>
        <LinkUnderline className="header-links--desktopLink" to="/blog/">
          Блог
        </LinkUnderline>
        <LinkUnderline className="header-links--desktopLink" to="/thesis/">
          Почему мы
        </LinkUnderline>
      </center>
      <div className="header-rightSide">
        <LinkUnderline className="header-links--desktopLink" to="/contact/">
          Консультация
        </LinkUnderline>
        <button onClick={expand} className="clean borgar">
          <Borgar />
        </button>
      </div>
      <nav className="header-menu">
        <LinkUnderline to="/cases/">Кейсы</LinkUnderline>
        <LinkUnderline to="/services/">Услуги</LinkUnderline>
        <LinkUnderline to="/portfolio/">Портфолио</LinkUnderline>
        <LinkUnderline to="/thesis/">Почему мы</LinkUnderline>
        <LinkUnderline to="/feedback/">Консультация</LinkUnderline>
      </nav>
    </header>
  );
};

export default Header;
