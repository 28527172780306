import React, { useEffect } from "react";
import { ScrollContext } from "../Contexts";
import { useMotionValueEvent, useScroll } from "framer-motion";

export default function Pop(props) {
  const { scrollY } = useScroll();
  const [visible, setVisible] = React.useState(false);

  const element = React.useRef(null);

  useMotionValueEvent(scrollY, "change", (latest) => {
    const windowHeight = window.innerHeight;
    if (element.current === null) return;
    if (element.current.getBoundingClientRect().top < windowHeight * 0.95) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  });

  return (
    <div
      ref={element}
      style={props.style}
      data-inert={!!props.inert}
      className={`state-pop ${visible ? "state-pop--show" : "state-pop--hide"}`}
    >
      {props.children}
    </div>
  );
}
